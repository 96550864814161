import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { MyaccountService } from '../../myaccount/myaccount.service';
import { PageDetailService } from '../../service/pageDetail.service';
declare const jQuery: any;

@Component({
  selector: 'app-header-front',
  styleUrls: [],
  templateUrl: './header-front.component.html',
})
export class HeaderFrontComponent implements OnInit {
  public checklogin: any = false;
  public catList: any;
  public catId = '';
  public usertype = 0;
  public msgCount = 0;

  public subCatId = '';
  public currentLink: any;
  public openClass: any = '';
  constructor(
    private authService: AuthService,
    private myaccountService: MyaccountService,
    private socialAuthService: SocialAuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private pageDetailService: PageDetailService
  ) {
    this.currentLink = this.router.url;
    // console.log(this.currentLink, 'this.currentLink ');
    this.route.params.subscribe((params) => {
      this.catId = params.catid; // (+) converts string 'id' to a number
      this.subCatId = params.subcatid;
    });
    this.usertype = this.authService.getUserType();
    this.pageDetailService.setMeta(this.currentLink);
  }

  public subMenuClicked(event): void {
    // console.log(event, 'event');
    if (event.target.className === 'pi pi-fw pi-chevron-down mob-sub-toggle') {
      event.target.className = 'pi pi-fw pi-chevron-up mob-sub-toggle';
      jQuery(event.target).parent().children('.submenu').addClass('d-block');
    } else {
      event.target.className = 'pi pi-fw pi-chevron-down mob-sub-toggle';
      jQuery(event.target).parent().children('.submenu').removeClass('d-block');
    }
    // console.log();
  }

  public toggleMenu(): void {
    if (this.openClass === '') {
      this.openClass = 'open';
    } else {
      this.openClass = '';
    }
  }

  public ngOnInit(): void {
    this.getCategory();
    this.checklogin = this.authService.checkUserLoggedIn();
    this.authService.decodeToken();
    if (this.checklogin) {
      this.getUnreadMsgCounts();
    }
  }

  public logout(): any {
    this.authService.userlogout();
  }

  public getCategory(formdata: any = null): any {
    const AngularThis = this;
    this.spinner.show();
    this.myaccountService.getAllCategory(formdata).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        // console.log(res.data, 'res');

        if (res.status === 1) {
          function sortByTitle(a, b) {
            if (!a.parentTitle || !b.parentTitle) return 0;
            // Use toUpperCase() to ignore character casing
            const aTitle = a.title.toUpperCase();
            const bTitle = b.title.toUpperCase();

            if (aTitle > bTitle) {
              return 1;
            } else if (aTitle < bTitle) {
              return -1;
            }
            return 0;
          }
          this.catList = res.data
            .sort(sortByTitle)
            .filter((cat) => cat.title !== 'Minibikes');
          myGlobalsfunc.saveLocalstorage(
            'categoryList',
            JSON.stringify(res.data)
          );

          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          // this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        // this.showError('error', error);
      }
    );
  }
  public getUnreadMsgCounts(): any {
    const req = {
      userId: window.localStorage.getItem('user_id_user'),
    };
    this.myaccountService.getUnreadMsgCounts(req).subscribe(
      (res: any) => {
        if (res.status === 1) {
          this.msgCount = res.data[0].msg_count;
          // console.log(res);
        }
      },
      (err: any) => {}
    );
  }
}
