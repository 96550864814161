import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { AdminComponent } from './admin/admin.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import { RecaptchaModule } from 'angular-google-recaptcha';
import {
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';
import {
  AmazonLoginProvider,
  FacebookLoginProvider,
  GoogleLoginProvider,
} from 'angularx-social-login';
import { NgxCaptchaModule } from 'ngx-captcha';
// Import library module
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { InplaceModule } from 'primeng/inplace';
import { TooltipModule } from 'primeng/tooltip';
import { AdProductService } from './admin/ad-service-product/ad-service-product.service';
// import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AdminComponent } from './admin/admin.component';
import { AddCategoryComponent } from './admin/category/add-category/add-category.component';
import { CategoryComponent } from './admin/category/category.component';
import { HeaderComponent } from './admin/common/header/header.component';
import { SidebarComponent } from './admin/common/sidebar/sidebar.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AddComponent } from './admin/listing/add/add.component';
import { ListingComponent } from './admin/listing/listing.component';
import { ForgotPasswordComponent } from './admin/login/forgot-password/forgot-password.component';
import { LoginComponent } from './admin/login/login.component';
import { ResetPasswordComponent } from './admin/login/reset-password/reset-password.component';
import { MessageService } from './admin/messages/message.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoggedInGuard } from './app.logged-in.guard';
import { LoginfrontComponent } from './front/loginfront/loginfront.component';
import { RegisterComponent } from './front/register/register.component';
import { AuthService } from './service/auth.service';
import { DataService } from './service/data.service';
import { TokenInterceptor } from './service/token.interceptor';

import { AddPriceManagementComponent } from './admin/price-management/add-price-management/add-price-management.component';
import { PriceManagementComponent } from './admin/price-management/price-management.component';
import { FooterFrontComponent } from './front/common/footer-front/footer-front.component';
import { HeaderFrontComponent } from './front/common/header-front/header-front.component';
import { HomeComponent } from './front/home/home.component';
import { ForgotPasswordFrontComponent } from './front/loginfront/forgot-password-front/forgot-password-front.component';
import { ResetPasswordFrontComponent } from './front/loginfront/reset-password-front/reset-password-front.component';
import { MyaccountComponent } from './front/myaccount/myaccount.component';
import { PostAdComponent } from './front/myaccount/post-ad/post-ad.component';
import { PostAdComponentBeta } from './front/myaccount/post-ad-beta/post-ad.component';
import { TestComponent } from './front/test/test.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxPhotoEditorModule } from 'ngx-photo-editor';
import { AddUserComponent } from './admin/user-management/add-user/add-user.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { AdDetailComponent } from './front/ad-detail/ad-detail.component';
import { CategoryDetailComponent } from './front/category-detail/category-detail.component';
import { ContactFormComponent } from './front/common/contact-form/contact-form.component';
import { ContactSellerComponent } from './front/contact-seller/contact-seller.component';
import { ContactUsComponent } from './front/contact-us/contact-us.component';
import { AccountInfoComponent } from './front/myaccount/account-info/account-info.component';
import { ManageMyPageComponent } from './front/myaccount/manage-my-page/manage-my-page.component';
import { MessagesComponent } from './front/myaccount/messages/messages.component';
import { ViewAdsComponent } from './front/myaccount/view-ads/view-ads.component';
import { SearchResultComponent } from './front/search-result/search-result.component';
import { ServiceProviderComponent } from './front/service-provider/service-provider.component';

import { AlertModule } from 'ngx-alerts';
import { NgxImageCompressService } from 'ngx-image-compress'; // It should be added too

import { NgImageSliderModule } from 'ng-image-slider';
import { GlobalsService } from './../providers/globals-service';
import { VideoService } from './../providers/video-service';
import { AddAdsComponent } from './admin/ads-management/add-ads/add-ads.component';
import { AdsManagementComponent } from './admin/ads-management/ads-management.component';
import { AddCmsComponent } from './admin/cms-management/add-cms/add-cms.component';
import { CmsManagementComponent } from './admin/cms-management/cms-management.component';
import { CreatePostComponent } from './create-post/create-post.component';
import { DragDropDirective } from './drag-drop.directive';
import { AboutUsComponent } from './front/about-us/about-us.component';
import { FaqComponent } from './front/faq/faq.component';
import { InfoComponent } from './front/myaccount/info/info.component';
import { MyWishlistComponent } from './front/myaccount/my-wishlist/my-wishlist.component';
import { ServiceProductComponent } from './front/myaccount/service-product/service-product.component';
import { SidemenuComponent } from './front/myaccount/sidemenu/sidemenu.component';
import { PrivacyPolicyComponent } from './front/privacy-policy/privacy-policy.component';
import { ServiceDetailComponent } from './front/services-list/service-detail/service-detail.component';
import { ServicesListComponent } from './front/services-list/services-list.component';
import { TermsAndConditionComponent } from './front/terms-and-condition/terms-and-condition.component';
import { ImagePickerComponent } from './image-picker/image-picker.component';

import { MatSliderModule } from '@angular/material/slider';
import { LightboxModule } from 'ngx-lightbox';
import { from } from 'rxjs';
import { ContactNotiComponent } from './admin//messages/contact-noti/contact-noti.component';
import { AdServiceProductComponent } from './admin/ad-service-product/ad-service-product.component';
import { AddProductComponent } from './admin/ad-service-product/add-product/add-product.component';
import { AddServiceProductComponent } from './admin/ad-service-product/edit-product/add-service-product/add-service-product.component';
import { EditProductComponent } from './admin/ad-service-product/edit-product/edit-product.component';
import { ContactMonitoringComponent } from './admin/messages/contact-monitoring/contact-monitoring.component';
import { MyProfileComponent } from './admin/my-profile/my-profile.component';
import { AdFeesComponent } from './front/ad-fees/ad-fees.component';
import { DiscountComponent } from './admin/discount/discount.component';
import { AddDiscountComponent } from './admin/discount/add-discount/add-discount.component';
import { PageNotFoundComponent } from './front/page-not-found/page-not-found.component';

const googleLoginOptions = {
  scope: 'profile email',
};

// const fbLoginOptions = {
//   enable_profile_selector: true,
//   return_scopes: true,
//   scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
// };

const fbLoginOptions = {
  scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list',
  return_scopes: true,
  enable_profile_selector: true,
  version: 'v2.11', // this line added.
};

// https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

// https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    AdminComponent,
    LoginComponent,
    ListingComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    AddComponent,
    CategoryComponent,
    AddCategoryComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginfrontComponent,
    RegisterComponent,
    PriceManagementComponent,
    HeaderFrontComponent,
    FooterFrontComponent,
    MyaccountComponent,
    AddPriceManagementComponent,
    HomeComponent,
    ForgotPasswordFrontComponent,
    ResetPasswordFrontComponent,
    TestComponent,
    PostAdComponent,
    PostAdComponentBeta,
    ServiceProviderComponent,
    AdDetailComponent,
    SearchResultComponent,
    CategoryDetailComponent,
    AccountInfoComponent,
    ViewAdsComponent,
    MessagesComponent,
    ManageMyPageComponent,
    ContactSellerComponent,
    ContactUsComponent,
    ContactFormComponent,
    UserManagementComponent,
    AddUserComponent,
    CreatePostComponent,
    ImagePickerComponent,
    DragDropDirective,
    MyWishlistComponent,
    AdsManagementComponent,
    AddAdsComponent,
    FaqComponent,
    ServicesListComponent,
    ServiceDetailComponent,
    SidemenuComponent,
    InfoComponent,
    ServiceProductComponent,
    TermsAndConditionComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    CmsManagementComponent,
    AddCmsComponent,
    ContactNotiComponent,
    ContactMonitoringComponent,
    AdServiceProductComponent,
    AddProductComponent,
    AdFeesComponent,
    MyProfileComponent,
    EditProductComponent,
    AddServiceProductComponent,
    DiscountComponent,
    AddDiscountComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // Configure the imports
    HttpClientModule,
    HttpModule,
    JsonpModule,
    MessagesModule,
    MessageModule,
    InputTextModule,
    InputTextareaModule,
    DropdownModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    AccordionModule,
    EditorModule,
    FileUploadModule,
    TableModule,
    TooltipModule,
    MatSliderModule,
    NgxSpinnerModule,
    NgxPayPalModule,
    SocialLoginModule,
    CarouselModule,
    ConfirmDialogModule,
    RecaptchaModule.forRoot({
      siteKey: '6Le4WLwZAAAAACSAM9BlWhu8-MD-hDEAGAJ3cmqY',
    }),
    NgxCaptchaModule,
    NgbModule,
    NgxIntlTelInputModule,
    NgxPhotoEditorModule,
    NgImageSliderModule,
    AlertModule.forRoot({
      maxMessages: 5,
      positionX: 'right',
      positionY: 'top',
      timeout: 2000,
    }),
    LightboxModule,
    ChartModule,
    InplaceModule,
  ],
  providers: [
    GlobalsService,
    VideoService,
    NgxImageCompressService,
    AuthService,
    LoggedInGuard,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    DataService,
    MessageService,
    AdProductService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              '301775364357217',
              fbLoginOptions
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
