<app-header-front></app-header-front>

<main class="main-content front login-front mb-0 pb-5">
    <div class="container">
        <div class="row justify-content-end">
            <div class="col-12 col-md-9 col-xl-10 ">
                <div class="login-register-form login-form">
                    <div class="form-box py-5 px-4 px-lg-5">
                        <div class="alert-message">
                            <p-messages [(value)]="msgs"></p-messages>
                        </div>
                        <!-- <countryflag country="en">English</countryflag>
                        <countryflag country="en" is-square="true">English <span class="caret"></span></countryflag> -->
                        <!-- <div *ngIf="user">
                            <img src="{{ user.photoUrl }}">
                            <h4>{{ user.name }}</h4>
                            <p>{{ user.email }}</p>
                        </div> -->
                        <h1 class="text-center">Register or Login to buy and sell on Racebuykz</h1>
                        <div class="row mx-lg-n5 mx--md-n4">
                            <div class="col-12 col-lg-6 px-md-3 mb-4 mb-lg-0 px-lg-5 order-lg-2">
                                <h4 class="mt-0 mb-3">Login</h4>
                                <form [formGroup]="userLoginForm" (ngSubmit)="onSubmit()" id="userLoginForm">
                                    <div class="input-row">
                                        <input #emailFocus type="email" formControlName="email" name="email" placeholder="Email/Username" class="form-control">
                                        <div *ngIf="fn.email.errors && fn.email.dirty" class="invalid-feedback">
                                            <div *ngIf="fn.email.errors.required" class="error-message-form"> Please enter email/username.</div>
                                            <div *ngIf="(fn.email.errors.email || fn.email.invalid) && fn.email.value" class="error-message-form">
                                                Please enter valid email/username.</div>
                                        </div>
                                    </div>
                                    <div class="input-row">
                                        <input formControlName="password" type="password" name="password" placeholder="Password" class="form-control">
                                        <div *ngIf="fn.password.errors && fn.password.dirty" class="invalid-feedback">
                                            <div *ngIf="fn.password.errors.required" class="error-message-form"> Please enter password.</div>
                                            <div *ngIf="(fn.password.errors.password || fn.password.invalid) && fn.password.value" class="error-message-form">
                                                Please enter valid password.</div>
                                        </div>
                                    </div>

                                    <input type="submit" [disabled]="!(userLoginForm.valid)" value="Login" class="btn btn-default px-5 float-right">
                                    <div class="remember-and-forgot-password float-left">
                                        <!-- <div class="password-remember">
                                            <input type="checkbox" id="rememberMe">
                                            <label for="rememberMe">Remember me</label>
                                        </div> -->
                                        <div class="forgot-password-link">
                                            <a [routerLink]="'/forgot-password'">Forgot Password?</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-12 col-lg-6 px-md-3 px-lg-5 border-right order-lg-1">
                                <h4 class="mt-0 mb-3">Register</h4>
                                <div [ngClass]="{
                                    'd-none': showRegisterForm,
                                    '': !showRegisterForm
                                }" class="register-sec">
                                    <div class="form-group">
                                        <a (click)="viewRegisterForm()" class="btn btn-default px-5 btn-block">Register</a>
                                    </div>
                                    <div class="login-with-facebook-btn">
                                        <a (click)="signInWithFB()" class="btn btn-default btn-block"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.124 96.123" width="16" fill="#fff">
                                                <g>
                                                    <path d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803	c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654
                        c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246
                        c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z" />
                                                </g>
                                            </svg> Login with Facebook</a>
                                    </div>
                                </div>

                                <div [ngClass]="{
                                    '': showRegisterForm,
                                    'd-none': !showRegisterForm
                                }" class="register-open">
                                    <form autocomplete="off" [formGroup]="userRegisterForm" (ngSubmit)="onRegisterSubmit()" id="userRegisterForm">

                                        <input id="username" style="display:none" type="text" name="fakeusernameremembered">
                                        <input id="password" style="display:none" type="password" name="fakepasswordremembered">

                                        <div class="row mx-n2">
                                            <div class="col-12 px-2">
                                                <div class="input-row position-relative">
                                                    <div class="info-popover info-popover-right pull-right">
                                                        <img src="../../../assets/images/info-icon.png" class="position-absolute info-icon" alt="info">
                                                        <div class="info-popover-content position-absolute">
                                                            <p>This is your public username, which will be displayed on any Ads you post, or on any communications to other buyers or sellers.</p>
                                                        </div>
                                                    </div>
                                                    <input type="text" formControlName="username" name="username" placeholder="Username" class="form-control form-control-info">
                                                    <div *ngIf="f.username.errors && f.username.dirty" class="invalid-feedback">
                                                        <div *ngIf="f.username.errors.required" class="error-message-form">Please enter username.</div>
                                                        <div *ngIf="f.username.errors.minlength" class="error-message-form">Username must have atleast 6 characters.
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 px-2">
                                                <div class="input-row">
                                                    <input (ngModelChange)="fieldChange()" type="text" formControlName="firstname" name="firstname" placeholder="First Name" class="form-control">

                                                    <div *ngIf="f.firstname.errors && f.firstname.dirty" class="invalid-feedback">
                                                        <div *ngIf="f.firstname.errors.required" class="error-message-form">Please enter firstname.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 px-2">
                                                <div class="input-row">
                                                    <input type="text" formControlName="lastname" name="lastname" placeholder="Last Name" class="form-control">
                                                    <div *ngIf="f.lastname.errors && f.lastname.dirty" class="invalid-feedback">
                                                        <div *ngIf="f.lastname.errors.required" class="error-message-form">Please enter lastname.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 px-2">
                                                <div class="input-row">
                                                    <input type="email" formControlName="email" name="email" placeholder="Email" class="form-control">
                                                    <div *ngIf="f.email.errors && f.email.dirty" class="invalid-feedback">
                                                        <div *ngIf="f.email.errors.required" class="error-message-form"> Please enter email.</div>
                                                        <div *ngIf="(f.email.errors.email || f.email.invalid) && f.email.value" class="error-message-form">
                                                            Please enter valid email.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 px-2">
                                                <div class="input-row">
                                                    <!-- <input type="text" ng2TelInput />

 -->
                                                    <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="['gb']" [selectedCountryISO]="'gb'" [enableAutoCountrySelect]="false" [enablePlaceholder]="true" placeholder="Phone" [searchCountryFlag]="true" [selectFirstCountry]="false" [maxLength]="15" [separateDialCode]="true" name="phone" [phoneValidation]="false" formControlName="phone">
                                                    </ngx-intl-tel-input>

                                                    <div *ngIf="f.phone.errors && f.phone.dirty" class="invalid-feedback">
                                                        <div *ngIf="f.phone.errors.required" class="error-message-form">Please enter phone.</div>
                                                    </div>
                                                    <!-- 
                                                    <international-phone-number name="phone_number"></international-phone-number> -->

                                                    <!-- 

                                                    <input type="text" formControlName="phone" name="phone" placeholder="Mobile Phone" class="form-control"> -->
                                                </div>
                                            </div>
                                            <div class="col-6 px-2">
                                                <div class="input-row">
                                                    <input type="password" formControlName="password" name="password" placeholder="Password" class="form-control">


                                                    <div *ngIf="f.password.errors && f.password.dirty" class="invalid-feedback">
                                                        <div *ngIf="f.password.errors.required" class="error-message-form"> Please enter password.</div>
                                                        <div *ngIf="(f.password.errors.password || f.password.invalid) && f.password.value" class="error-message-form">
                                                            The password must have at least 6 characters and a mix of letters and numbers.</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-6 px-2">
                                                <div class="input-row">
                                                    <input type="password" formControlName="confirm_password" name="repeatpassword" placeholder="Confirm Password" class="form-control">

                                                    <div *ngIf="f.confirm_password.errors && f.confirm_password.dirty" class="invalid-feedback">
                                                        <div *ngIf="f.confirm_password.errors.required" class="error-message-form">Please enter confirm password.
                                                        </div>
                                                        <div *ngIf="f.confirm_password.errors" class="error-message-form">Please enter valid confirm password
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 px-2">
                                                <div class="input-row">
                                                    <div class="google-captcha">
                                                        <!-- Google captcha -->
                                                        <ngx-recaptcha2 #captchaElem [siteKey]="'6Le4WLwZAAAAACSAM9BlWhu8-MD-hDEAGAJ3cmqY'" formControlName="recaptcha">
                                                        </ngx-recaptcha2>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 px-2">
                                                <div class="form-check">
                                                    <input formControlName="isAgreeTerms" type="checkbox" class="form-check-input" id="terms-and-conditions">
                                                    <label for="terms-and-conditions">I agree to Racebuykz Terms and Conditions
                                                        and Privacy Policy</label>
                                                </div>
                                                <div class="form-check">
                                                    <input formControlName="isNewsAndUpdates" type="checkbox" class="form-check-input" id="news-and-updates">
                                                    <label for="news-and-updates">Receive News and Updates</label>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-3 px-2">
                                                <input type="submit" [disabled]="!(userRegisterForm.valid)" value="Register" class="btn btn-default px-5 float-left">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="login-register-link float-left w-100 mt-4  text-center">
                        <span class="d-inline-block py-2 px-4 br-3">Are you a Service Provider? <a [routerLink]="'/register-service-provider'">Register Here</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bike-img d-none d-md-block">
        <img src="../../../assets/images/bike.png" alt="bike">
    </div>
</main>
<app-footer-front></app-footer-front>
