import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { MyaccountService } from '../myaccount.service';

@Component({
  selector: 'app-view-ads',
  templateUrl: './view-ads.component.html',
  styleUrls: []
})
export class ViewAdsComponent implements OnInit {

  public websiteurl: any;
  public allDataInitial: any;
  public allData: any[]=[];
  public msgs: any = [];
  public currentLink: any;
  public isDraftPage: any = false;
  public isExpirePage: any = false;



  constructor(
    private myaccountService: MyaccountService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
    public confirmationService:ConfirmationService
  ) {
    this.authService.isUserLoggedIn();
    this.currentLink = this.router.url;
    if (this.currentLink === '/adverts/edit-ad') {
      this.isDraftPage = false;
      this.isExpirePage = false;
    } else if (this.currentLink === '/adverts/draft-ad') {
      this.isDraftPage = true;
      this.isExpirePage = false;
    } else if (this.currentLink === '/adverts/expire-ad') {
      this.isDraftPage = false;
      this.isExpirePage = true;
    }
  }

  public ngOnInit(): void {
    this.getAllSavedAd();
  }

  public calculateDiff(dateSent): any {
    const currentDate = new Date();
    dateSent = new Date(dateSent);

    let responseString = '';
    let res = Math.floor((Date.UTC(currentDate.getFullYear(),
      currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(),
        dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));

    if (res > 90) {
      responseString = '90+ days';
    } else {
      responseString = res + ' days';
    }

    if (res === 0) {
      res = Math.floor((Date.UTC(currentDate.getFullYear(),
        currentDate.getMonth(), currentDate.getDate(),
        currentDate.getHours()) - Date.UTC(dateSent.getFullYear(),
          dateSent.getMonth(), dateSent.getDate(), dateSent.getHours())) / (1000 * 60 * 60));

      responseString = res + ' hours';

      if (res === 0) {
        res = Math.floor((Date.UTC(currentDate.getFullYear(),
          currentDate.getMonth(), currentDate.getDate(),
          currentDate.getHours(), currentDate.getMinutes()) - Date.UTC(dateSent.getFullYear(),
            dateSent.getMonth(), dateSent.getDate(), dateSent.getHours(), dateSent.getMinutes())) / (1000 * 60));

        responseString = res + ' mins';
      }
    }

    return responseString;

  }

  public showError(type, message): void {
    // console.log(type, message);
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

  public removeFromWishlist(wid: any): void {

    // alert(wid);
    const req = {
      id: wid,
    };
    this.spinner.show();
    this.myaccountService.removeFromWishlist(req).subscribe(
      (res: any) => {

        // this.showError('success', 'Ad has been added to wishlist successfully.');
        if (res.status === 1) {
          window.scroll(0, 0);
          this.spinner.hide();
          this.showError('success', 'Ad has been deleted from wishlist successfully.');
          this.getAllSavedAd();

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        // this.showError('error', error);
      },
    );

  }

  public changeSold(id: any, isSold: any): void {
    console.log(id, isSold, 'changeSold');
    const req = {
      id,
      isSold,
    };

    this.spinner.show();
    this.myaccountService.updateSoldAd(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        if (res.status === 1) {
          this.getAllSavedAd();
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
      },
    );

  }

  public getAllSavedAd(): void {
    let isPublished = 1;
    let isExpire = false;

    if (this.isDraftPage) {
      isPublished = 0;
    }
    if (this.isExpirePage) {
      isExpire = true;
      isPublished = 0;
    }

    const req = {
      isExpire,
      isPublished,
      user_id: window.localStorage.getItem('user_id_user'),
    };


    this.websiteurl = global_var.webSiteUrl;

    this.spinner.show();

    this.myaccountService.getSavedPostAd(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message

        if (res.status === 1) {

          this.allDataInitial = res.data;
          if (res.data.length > 0) {

            const AngularThis = this;

            this.allDataInitial.forEach((part, index, theArray) => {
              theArray[index].images = JSON.parse(part.images);
              theArray[index].diff = AngularThis.calculateDiff(part.dateCreated);

              if (theArray[index].title.length > 25) {
                theArray[index].title = theArray[index].title.substring(0, 28) + '...';
              }

              let imgArray;
              const imgArraynew = [];

              if (theArray[index].images && theArray[index].images.length > 0) {
                theArray[index].images.forEach((partChild, indexChild, theArrayChild) => {
                  imgArray = {
                    alt: '',
                    image: AngularThis.websiteurl + "image/" + partChild,
                    thumbImage: AngularThis.websiteurl + "image/" + partChild,
                    title: '',
                  };
                  imgArraynew.push(imgArray);
                });
              }


              theArray[index].imageObject = imgArraynew;
            });
            const newarry = this.allDataInitial;
            this.allData = newarry;

            console.log('this.allData', this.allData);
          } else {
            this.allData = null;

          }

          this.spinner.hide();

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
      },
    );

  }
  public deleteFromDraft(id: any) {
    this.confirmationService.confirm({
      acceptButtonStyleClass:'popupbutton',
      rejectButtonStyleClass:'popupbutton',
      message: 'Are you sure that you want to delete?',
      accept: () => {
        this.spinner.show();
        let req = {
          id: id
        }
        this.myaccountService.deleteAd(req).subscribe((res: any) => {
          this.spinner.hide();
          if (res.status == 1)
          {
            let idx=this.allData.findIndex(o=>o.id==id)
            if(idx>-1)
            this.allData.splice(idx,1);
            this.showError("success", "Ad deleted successfully.");
          }           
        }, (err: any) => {
          this.spinner.hide();
          this.showError("error", "There is some error. Please try again later.")
        })
      }
  });
  }

}
