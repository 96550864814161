import { EventEmitter, Injectable } from '@angular/core';
import { CanActivate, Router, RouterModule, Routes } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as myGlobalsfunc from '../global.function';
import * as myGlobals from '../global.variable';
import { LoginRegisterService } from './../front/service/loginRegister.service';

const helper = new JwtHelperService();

// import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
    private loggedIn = false;
    private userlogoutStatus;
    constructor(
        private router: Router,
        private loginRegisterService: LoginRegisterService,
    ) {
        this.loggedIn = !!window.localStorage.getItem('access_token');
    }

    public decodeToken(): any {
        // console.log(helper.decodeToken(window.localStorage.getItem('access_token_user')), Date.now() / 1000);
    }

    public adminlogout(): any {

        // console.log('Check 4');
        const data = { command: 'Disconnect', user_id: window.localStorage.getItem('user_id') };
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('user_id');
        window.localStorage.removeItem('expires_in');
        window.localStorage.removeItem('email');

        window.localStorage.setItem('logout_msg', '1');
        window.localStorage.removeItem('login_msg');
        // console.log(data);
        this.loggedIn = false;
        this.router.navigate(['/admin/login']);
        // myGlobals.comet.send(JSON.stringify(data));
    }

    public userlogout(): any {

        // console.log('Check 4');
        // const data = { command: 'Disconnect', user_id: window.localStorage.getItem('user_id') };
        window.localStorage.removeItem('access_token_user');
        window.localStorage.removeItem('user_id_user');
        window.localStorage.removeItem('expires_in');
        window.localStorage.removeItem('email');

        window.localStorage.setItem('logout_msg', '1');
        window.localStorage.removeItem('login_msg');
        // console.log(data);
        this.loggedIn = false;
        this.router.navigate(['/login']);
        // myGlobals.comet.send(JSON.stringify(data));
    }


    public unsetUser(): any {
        window.localStorage.removeItem('access_token_user');
        window.localStorage.removeItem('user_id_user');
        window.localStorage.removeItem('expires_in');
        window.localStorage.removeItem('email');
        window.localStorage.setItem('logout_msg', '1');
        window.localStorage.removeItem('login_msg');
        this.loggedIn = false;
    }

    public isLoggedIn(): any {

        const getDetail = helper.decodeToken(window.localStorage.getItem('access_token'));

        if (window.localStorage.getItem('access_token')) {
            if (getDetail.exp < Date.now() / 1000) {
                this.adminlogout();
            }
        }

        // console.log('2');
        if (!window.localStorage.getItem('user_id') || !window.localStorage.getItem('access_token')) {
            // console.log('3');
            this.router.navigate(['/admin/login']);
        } else {
            this.loggedIn = true;
        }
        // console.log('1');
        return this.loggedIn;
    }

    public isUserLoggedIn(): any {

        const getDetail = helper.decodeToken(window.localStorage.getItem('access_token_user'));

        if (window.localStorage.getItem('access_token_user')) {
            if (getDetail.exp !== undefined && getDetail.exp < Date.now() / 1000) {
                this.userlogout();
            }
        }
        if (!window.localStorage.getItem('user_id_user') || !window.localStorage.getItem('access_token_user')) {
            // console.log('3');
            this.router.navigate(['/login']);
        } else {
            this.loggedIn = true;
        }

        return this.loggedIn;
    }

    public getUserType(): any {
        // console.log(window.localStorage.getItem('access_token_user'));
        // console.log(typeof window.localStorage.getItem('access_token_user'));

        if (window.localStorage.getItem('access_token_user') &&
            window.localStorage.getItem('access_token_user') !== '' && window.localStorage.getItem('access_token_user') !== undefined
            && window.localStorage.getItem('access_token_user') !== 'undefined') {
            const getDetail = helper.decodeToken(window.localStorage.getItem('access_token_user'));

            if (window.localStorage.getItem('access_token_user')) {
                if (getDetail.usertype !== undefined) {
                    return getDetail.usertype;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        } else {
            window.localStorage.removeItem('access_token_user');
            window.localStorage.removeItem('user_id_user');
            return 0;

        }

    }

    public getUserId(): any {
        // console.log(window.localStorage.getItem('access_token_user'));
        // console.log(typeof window.localStorage.getItem('access_token_user'));

        if (window.localStorage.getItem('access_token_user') &&
            window.localStorage.getItem('access_token_user') !== '' && window.localStorage.getItem('access_token_user') !== undefined
            && window.localStorage.getItem('access_token_user') !== 'undefined') {

            if (window.localStorage.getItem('access_token_user')) {

                const getDetail = helper.decodeToken(window.localStorage.getItem('access_token_user'));

                if (getDetail.id !== undefined) {
                    return getDetail.id;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        } else {
            window.localStorage.removeItem('access_token_user');
            window.localStorage.removeItem('user_id_user');
            return 0;

        }

    }

    public checkUserLoggedIn(): boolean {
        // console.log('2');
        const getDetail = helper.decodeToken(window.localStorage.getItem('access_token_user'));

        if (window.localStorage.getItem('access_token_user')) {
            if (getDetail.exp !== undefined && getDetail.exp < Date.now() / 1000) {
                this.unsetUser();
                return this.loggedIn = false;

            }
        } else {
            return this.loggedIn = false;
        }

        if (!window.localStorage.getItem('user_id_user') || !window.localStorage.getItem('access_token_user')) {
            // console.log('3');
            this.unsetUser();
            this.loggedIn = false;
        } else {
            this.loggedIn = true;
            this.UserConfirmLogin();
        }
        // console.log('1');
        return this.loggedIn;
    }

    public UserConfirmLogin(): any {
        const req = {
            access_token_user: window.localStorage.getItem('access_token_user'),
        };
        this.loginRegisterService.loginConfirm(req).subscribe(
            (res: any) => {
                // if the response status is 1 the user is sucessfully logged in else show error message
                // console.log(res, 'res');
                if (res.status === 1) {
                    // window.scroll(0, 0);

                    myGlobalsfunc.saveLocalstorage('access_token_user', res.token);
                    myGlobalsfunc.saveLocalstorage('expires_in', res.user.exp);
                    myGlobalsfunc.saveLocalstorage('email', res.user.email);
                    myGlobalsfunc.saveLocalstorage('user_id_user', res.user.id);

                } else if (res.status === 2) {
                } else if (res.status === 0) {
                } else {
                }
            },
            // Log any error occured in logging in
            (error) => {
            },
        );

    }

    public moveToDashboardIfAdminLoggedIn(): any {

        if (window.localStorage.getItem('user_id') || window.localStorage.getItem('access_token')) {
            this.router.navigate(['admin']);
        }
        return this.loggedIn;
    }

    // public isTokenExpired() {
    //     try {
    //         const decoded = decode(token);
    //         if (decoded.exp < Date.now() / 1000) {
    //             // Checking if token is expired.
    //             return true
    //         } else return false
    //     } catch (err) {
    //         console.error('Token Expired!')
    //         return false
    //     }
    // }

    public moveToMyAccountIfUserLoggedIn(): any {

        if (this.checkUserLoggedIn()) {

            if (this.getUserType() === 2) {
              this.router.navigate(['advertise-here/edit-info']);
            } else {
              this.router.navigate(['advertise-here/edit-profile']);
            }

        } else {

        }
        // return this.loggedIn;
    }

    public canActivate(): any {
        if (!this.isLoggedIn()) {
            this.router.navigate(['/admin/login']);
            return false;
        }
    }
    public getToken(): any {
        return window.localStorage.getItem('access_token_user');
    }

    public getAdminToken(): any {
        return window.localStorage.getItem('access_token');
    }

}
