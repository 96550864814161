<app-header-front></app-header-front>
<main class="main-content front home">
  <section
    *ngIf="bannerData"
    style="background: url('{{
      imageurl + bannerData.image
    }}') center center no-repeat;"
    class="header-slider py-5"
  >
    <div class="item">
      <div class="container">
        <div class="row">
          <div id="hero-header" class="col-12 mb-4 mb-lg-0 col-lg-7">
            <div class="caption py-3 px-4">
              <h2>{{ bannerData.title }}</h2>
              <p>{{ bannerData.subtitle }}</p>
            </div>
          </div>
          <div
            id="mobile-hero-nav"
            class="mobile-hero-nav col-12 d-flex justify-content-around d-lg-none"
          >
            <a class="mobile-hero-nav-button" href="/category/parts">Parts</a>
            <button class="mobile-hero-nav-button" (click)="showSearch()">
              Search
            </button>
            <a class="mobile-hero-nav-button" href="/category/bikes">Bikes</a>
          </div>
          <div
            id="hero-search-form"
            class="col-12 col-lg-5 pl-lg-5 d-none d-lg-block"
          >
            <div class="form-box mx-n3 mx-lg-0">
              <div class="form">
                <h1>Search Here</h1>
                <form [formGroup]="searchForm" (ngSubmit)="onSearchSubmit()">
                  <div class="row">
                    <div class="p-field col-md-12 input-field">
                      <p-dropdown
                        inputId="category"
                        formControlName="categoryId"
                        optionLabel="title"
                        [options]="parentCatList"
                        (onChange)="changeParentList($event)"
                        placeholder="Ad category"
                      ></p-dropdown>
                    </div>
                    <div class="p-field col-md-12 input-field">
                      <p-dropdown
                        placeholder="Category"
                        formControlName="subCategoryId"
                        (onChange)="changeSubCatList($event)"
                        inputId="capacity"
                        optionLabel="title"
                        [options]="subCatList"
                      ></p-dropdown>
                    </div>

                    <div
                      *ngIf="bikeSelected"
                      class="p-field col-md-12 input-field"
                    >
                      <p-dropdown
                        optionLabel="name"
                        formControlName="manufacturer"
                        (onChange)="changemanufacturer($event)"
                        placeholder="Make"
                        [options]="manufacturerList"
                      ></p-dropdown>
                    </div>

                    <div
                      *ngIf="manufacturerOthers"
                      class="p-field col-md-12 input-field"
                    >
                      <input
                        formControlName="manufacturertext"
                        id="manufacturertext"
                        type="text"
                        placeholder="Make"
                        pInputText
                      />
                    </div>

                    <div
                      *ngIf="bikeSelected"
                      class="p-field col-md-12 input-field"
                    >
                      <!-- <p-dropdown inputId="category" [options]="categories" placeholder="Capacity" optionLabel="adCategory"></p-dropdown> -->

                      <p-dropdown
                        formControlName="subSubCategoryId"
                        inputId="capacity"
                        placeholder="Capacity"
                        optionLabel="title"
                        [options]="subSubCatList"
                      ></p-dropdown>
                    </div>
                    <div class="p-field col-sm-6 input-field">
                      <input
                        (keypress)="allowInteger($event)"
                        formControlName="minPrice"
                        id="minPrice"
                        type="text"
                        placeholder="Min price"
                        pInputText
                      />

                      <!-- <p-dropdown inputId="category" [options]="categories" placeholder="Min price" optionLabel="minPrice"></p-dropdown> -->
                    </div>
                    <div class="p-field col-sm-6 input-field">
                      <input
                        (keypress)="allowInteger($event)"
                        formControlName="maxPrice"
                        id="maxPrice"
                        type="text"
                        placeholder="Max price"
                        pInputText
                      />
                      <!-- <p-dropdown inputId="category" [options]="categories" placeholder="Max price" optionLabel="maxPrice"></p-dropdown> -->
                    </div>
                    <!-- <div class="p-field col-md-12 input-field">
                                        <p-dropdown inputId="category" [options]="categories" placeholder="Post code" optionLabel="Post code"></p-dropdown>
                                    </div>
                                    <div class="p-field col-md-12 input-field">
                                        <p-dropdown inputId="category" [options]="categories" placeholder="Country" optionLabel="name"></p-dropdown>
                                    </div> -->
                  </div>
                  <div class="form-btn">
                    <button
                      pButton
                      type="submit"
                      class="btn btn-default btn-black btn-block mb-2"
                      label="Search"
                    ></button>
                    <button
                      (click)="setInitialForm()"
                      pButton
                      type="reset"
                      class="btn btn-default link-btn reset-btn"
                      label="Reset"
                    ></button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="featured-products">
    <div class="container">
      <div class="section-title display-flex">
        <h2>What's Hot</h2>
        <div class="form-filter">
          <!-- <p-dropdown inputId="category" [options]="categories" placeholder="Sort by Distance" optionLabel="Post code"></p-dropdown> -->
        </div>
      </div>
      <div class="row product-grid">
        <ng-container *ngIf="allFeaturedData">
          <p-carousel
            class="col-12"
            [value]="allFeaturedData"
            [numScroll]="3"
            [numVisible]="3"
            [infiniteLoop]="true"
            [circular]="true"
            [responsiveOptions]="responsiveOptions"
          >
            <ng-template let-item pTemplate="item">
              <!-- <h4 class="p-mb-1">{{item.title}}</h4> -->

              <div class="col-12 fatured-col">
                <div class="product-item">
                  <div class="product-img-main">
                    <div class="wishlist">
                      <svg
                        viewBox="0 -28 512.001 512"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#fff"
                      >
                        <path
                          _ngcontent-pdv-c117=""
                          d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0"
                        ></path>
                      </svg>
                    </div>
                    <!-- <div class="product-img">
                                            <img src="../../../assets/images/featured-products-1.jpg" alt="">
                                            <span class="featured">Featured Bike</span>
                                        </div> -->

                    <div *ngIf="item.images" class="product-img">
                      <a [routerLink]="'/ad-detail/' + item.slugName"
                        ><img
                          src="{{
                            websiteurl +
                              'image/' +
                              (item.featureImage
                                ? item.featureImage
                                : item.images[0])
                          }}?thumbnail=350"
                          alt=""
                        />
                      </a>
                      <span class="featured">Featured Bike</span>
                    </div>
                  </div>
                  <div class="product-info">
                    <div class="product-name">
                      <span class="name pull-left">{{ item.title }}</span>
                    </div>
                    <div class="product-price">
                      <span class="price pull-left"
                        >Price: {{ item.price | currency: "GBP" }}</span
                      >

                      <span class="share pull-right">
                        <svg
                          enable-background="new 0 0 482.239 482.239"
                          height="100%"
                          viewBox="0 0 482.239 482.239"
                          width="100%"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="m378.902 275.565c-34.183 0-64.306 16.874-83.127 42.521l-94.044-47.033c2.912-9.539 4.943-19.456 4.943-29.934s-2.031-20.397-4.943-29.934l94.044-47.033c18.821 25.647 48.944 42.521 83.127 42.521 56.983 0 103.337-46.354 103.337-103.337s-46.353-103.336-103.337-103.336-103.337 46.354-103.337 103.337c0 10.478 2.033 20.395 4.945 29.932l-94.047 47.035c-18.821-25.647-48.944-42.521-83.127-42.521-56.983 0-103.336 46.353-103.336 103.337s46.353 103.337 103.337 103.337c34.183 0 64.306-16.874 83.127-42.521l94.044 47.035c-2.912 9.536-4.943 19.453-4.943 29.932 0 56.983 46.353 103.337 103.337 103.337s103.337-46.354 103.337-103.337-46.354-103.338-103.337-103.338zm0-241.119c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.897 68.891-68.891 68.891-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891zm-275.565 275.565c-37.995 0-68.891-30.914-68.891-68.891 0-37.978 30.897-68.891 68.891-68.891 37.995 0 68.891 30.914 68.891 68.891s-30.896 68.891-68.891 68.891zm275.565 137.782c-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.896 68.891-68.891 68.891z"
                          />
                        </svg>
                        <div class="share-items">
                          <span
                            ><a
                              href="https://www.facebook.com/sharer/sharer.php?u={{
                                websiteurl
                              }}ad-detail/{{ item.slugName }}"
                              target="_blank"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 96.124 96.123"
                                width="16"
                                fill="#fff"
                              >
                                <g>
                                  <path
                                    d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803	c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654
                                          c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246
                                          c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z"
                                  />
                                </g></svg></a
                          ></span>

                          <span
                            ><a
                              href="mailto:?subject=The best site for Motorcycle Racing and Trackday Ads&body=view {{
                                item.title
                              }} here {{ websiteurl }}ad-detail/{{
                                item.slugName
                              }}"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                              >
                                <path fill="#fff" d="M0 0h24v24H0V0z"></path>
                                <path
                                  fill="#000000"
                                  d="M4 18h16V6H4v12zM2 6c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6zm15.36 2.23L12 12.7 6.64 8.23a1 1 0 1 0-1.28 1.54l6 5a1 1 0 0 0 1.28 0l6-5a1 1 0 1 0-1.28-1.54z"
                                ></path>
                              </svg> </a
                          ></span>
                          <span
                            ><a
                              href="https://twitter.com/intent/tweet?text={{
                                websiteurl
                              }}ad-detail/{{ item.slugName }}"
                              target="_blank"
                              ><svg
                                viewBox="0 -47 512.00203 512"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                fill="#fff"
                              >
                                <path
                                  d="m191.011719 419.042969c-22.140625 0-44.929688-1.792969-67.855469-5.386719-40.378906-6.335938-81.253906-27.457031-92.820312-33.78125l-30.335938-16.585938 32.84375-10.800781c35.902344-11.804687 57.742188-19.128906 84.777344-30.597656-27.070313-13.109375-47.933594-36.691406-57.976563-67.175781l-7.640625-23.195313 6.265625.957031c-5.941406-5.988281-10.632812-12.066406-14.269531-17.59375-12.933594-19.644531-19.78125-43.648437-18.324219-64.21875l1.4375-20.246093 12.121094 4.695312c-5.113281-9.65625-8.808594-19.96875-10.980469-30.777343-5.292968-26.359376-.863281-54.363282 12.476563-78.851563l10.558593-19.382813 14.121094 16.960938c44.660156 53.648438 101.226563 85.472656 168.363282 94.789062-2.742188-18.902343-.6875-37.144531 6.113281-53.496093 7.917969-19.039063 22.003906-35.183594 40.722656-46.691407 20.789063-12.777343 46-18.96875 70.988281-17.433593 26.511719 1.628906 50.582032 11.5625 69.699219 28.746093 9.335937-2.425781 16.214844-5.015624 25.511719-8.515624 5.59375-2.105469 11.9375-4.496094 19.875-7.230469l29.25-10.078125-19.074219 54.476562c1.257813-.105468 2.554687-.195312 3.910156-.253906l31.234375-1.414062-18.460937 25.230468c-1.058594 1.445313-1.328125 1.855469-1.703125 2.421875-1.488282 2.242188-3.339844 5.03125-28.679688 38.867188-6.34375 8.472656-9.511718 19.507812-8.921875 31.078125 2.246094 43.96875-3.148437 83.75-16.042969 118.234375-12.195312 32.625-31.09375 60.617187-56.164062 83.199219-31.023438 27.9375-70.582031 47.066406-117.582031 56.847656-23.054688 4.796875-47.8125 7.203125-73.4375 7.203125zm0 0"
                                /></svg></a
                          ></span>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="product-disc new-d d-flex">
                    <div *ngIf="item.age" class="pro-options age">
                      <span>{{ item.age }}</span>
                    </div>
                    <div *ngIf="item.maincatname" class="pro-options race">
                      <span>{{ item.maincatname }}</span>
                    </div>
                    <div *ngIf="item.postcode" class="pro-options postcode">
                      <span>{{ item.postcode.substring(0, 4) }}</span>
                    </div>
                  </div>
                  <div class="btns-row mb-2">
                    <!-- <a href="#" class="btn btn-default btn-black pull-left">View Ad</a> -->

                    <a
                      [routerLink]="'/ad-detail/' + item.slugName"
                      class="btn btn-default btn-black pull-left"
                      >View Ad</a
                    >

                    <!-- <a href="#" class="btn btn-default pull-right">Contact Seller</a> -->

                    <a
                      *ngIf="item.isSold == 0"
                      [routerLink]="
                        '/contact-us/' + item.id + '/' + item.createdBy
                      "
                      class="btn btn-default pull-right"
                      >Contact Seller</a
                    >

                    <a
                      *ngIf="item.isSold == 1"
                      class="btn btn-default pull-right"
                      >Sold</a
                    >
                  </div>
                </div>
              </div>
            </ng-template>
          </p-carousel>
        </ng-container>
      </div>
      <div class="bottom-shadow">
        <img
          src="../../../assets/images/bottom-shadow.png"
          alt="bottom-shadow"
        />
      </div>
    </div>
  </section>
  <section class="offers-and-ads">
    <div class="container">
      <div class="row">
        <div class="col-md-9 offers">
          <div class="section-title">
            <h2>Ad Types</h2>
          </div>
          <div class="row homepagecats">
            <ng-container *ngFor="let item of featuredcats; let i = index">
              <div
                *ngIf="
                  (item.parentId == 0 && item.id != 1) || item.parentId == 1
                "
                class="col-md-4 col-lg-3 offer-col align-center"
              >
                <ng-container *ngIf="item.parentId == 0 && item.id != 45">
                  <a [routerLink]="'/category/' + item.slugName">
                    <div class="content-box">
                      <div class="icon">
                        <img
                          src="{{ websiteurl + 'image/' + item.bannerImage }}"
                          alt="product image"
                        />
                      </div>
                      <div class="content">
                        <h6>{{ item.title }}</h6>
                        <!-- <p>{{item.description}}</p> -->

                        <div [innerHTML]="item.description"></div>
                      </div>
                    </div>
                  </a>
                </ng-container>

                <ng-container *ngIf="item.parentId == 0 && item.id == 45">
                  <a [routerLink]="'/sp-category'">
                    <div class="content-box">
                      <div class="icon">
                        <img
                          src="{{ websiteurl + 'image/' + item.bannerImage }}"
                          alt="product image"
                        />
                      </div>
                      <div class="content">
                        <h6>{{ item.title }}</h6>
                        <!-- <p>{{item.description}}</p> -->

                        <div [innerHTML]="item.description"></div>
                      </div>
                    </div>
                  </a>
                </ng-container>

                <ng-container *ngIf="item.parentId == 1">
                  <a
                    [routerLink]="
                      '/category/' + item.parentslugName + '/' + item.slugName
                    "
                  >
                    <div class="content-box">
                      <div class="icon">
                        <img
                          src="{{ websiteurl + 'image/' + item.bannerImage }}"
                          alt="product image"
                        />
                      </div>
                      <div class="content">
                        <h6>{{ item.title }}</h6>
                        <!-- <p>{{item.description}}</p> -->

                        <div [innerHTML]="item.description"></div>
                      </div>
                    </div>
                  </a>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-md-3 ads-col align-center">
          <ng-container *ngIf="servicesData">
            <div *ngFor="let item of servicesData" class="ad">
              <a [routerLink]="'/service-providers/' + item.slugName">
                <img src="{{ websiteurl + 'image/' + item.adImage }}" alt="" />
              </a>
            </div>
          </ng-container>

          <!-- <div class="ad">
                        <img src="../../../../assets/images/titan-x.jpg" alt="bike">
                       
                    </div> -->
        </div>
      </div>
    </div>
  </section>
  <section class="text-center sell-bike">
    <div *ngIf="cmsdata" class="sell-bike-info">
      <h2>{{ cmsdata.subtitle }}</h2>
      <p style="white-space: pre-wrap">{{ cmsdata.shortDescription }}</p>
      <a [routerLink]="'/faq'" class="btn btn-default btn-white"
        ><span>FAQ's</span></a
      >
    </div>
  </section>
  <section class="sell-bike pb-0 pb-md-5">
    <div class="contact-form-section">
      <div class="container">
        <div class="row">
          <div class="col-md-5 bike-img pt-5 pt-sm-0">
            <img src="../../../../assets/images/footer-bike.png" alt="bike" />
          </div>
          <div class="col-md-7">
            <app-contact-form></app-contact-form>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer-front></app-footer-front>
