import { NgModule } from '@angular/core';
import { ActivatedRoute, RouterModule, Routes } from '@angular/router';
import { Angulartics2Module } from 'angulartics2';

import { AdServiceProductComponent } from './admin/ad-service-product/ad-service-product.component';
import { AddProductComponent } from './admin/ad-service-product/add-product/add-product.component';
import { AddAdsComponent } from './admin/ads-management/add-ads/add-ads.component';
import { AdsManagementComponent } from './admin/ads-management/ads-management.component';
import { AddCategoryComponent } from './admin/category/add-category/add-category.component';
import { CategoryComponent } from './admin/category/category.component';
import { AddCmsComponent } from './admin/cms-management/add-cms/add-cms.component';
import { CmsManagementComponent } from './admin/cms-management/cms-management.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AddComponent } from './admin/listing/add/add.component';
import { ListingComponent } from './admin/listing/listing.component';
import { ForgotPasswordComponent } from './admin/login/forgot-password/forgot-password.component';
import { LoginComponent } from './admin/login/login.component';
import { ResetPasswordComponent } from './admin/login/reset-password/reset-password.component';
import { ContactMonitoringComponent } from './admin/messages/contact-monitoring/contact-monitoring.component';
import { ContactNotiComponent } from './admin/messages/contact-noti/contact-noti.component';
import { AddPriceManagementComponent } from './admin/price-management/add-price-management/add-price-management.component';
import { PriceManagementComponent } from './admin/price-management/price-management.component';
import { AddUserComponent } from './admin/user-management/add-user/add-user.component';
import { MyProfileComponent } from './admin/my-profile/my-profile.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { LoggedInGuard } from './app.logged-in.guard';
import { CreatePostComponent } from './create-post/create-post.component';
import { AboutUsComponent } from './front/about-us/about-us.component';
import { AdDetailComponent } from './front/ad-detail/ad-detail.component';
import { AdFeesComponent } from './front/ad-fees/ad-fees.component';
import { CategoryDetailComponent } from './front/category-detail/category-detail.component';
import { ContactSellerComponent } from './front/contact-seller/contact-seller.component';
import { ContactUsComponent } from './front/contact-us/contact-us.component';
import { FaqComponent } from './front/faq/faq.component';
import { HomeComponent } from './front/home/home.component';
import { ForgotPasswordFrontComponent } from './front/loginfront/forgot-password-front/forgot-password-front.component';
import { LoginfrontComponent } from './front/loginfront/loginfront.component';
import { ResetPasswordFrontComponent } from './front/loginfront/reset-password-front/reset-password-front.component';
import { AccountInfoComponent } from './front/myaccount/account-info/account-info.component';
import { InfoComponent } from './front/myaccount/info/info.component';
import { ManageMyPageComponent } from './front/myaccount/manage-my-page/manage-my-page.component';
import { MessagesComponent } from './front/myaccount/messages/messages.component';
import { MyWishlistComponent } from './front/myaccount/my-wishlist/my-wishlist.component';
import { MyaccountComponent } from './front/myaccount/myaccount.component';
import { PostAdComponent } from './front/myaccount/post-ad/post-ad.component';
import { PostAdComponentBeta } from './front/myaccount/post-ad-beta/post-ad.component';
import { ServiceProductComponent } from './front/myaccount/service-product/service-product.component';
import { ViewAdsComponent } from './front/myaccount/view-ads/view-ads.component';
import { PrivacyPolicyComponent } from './front/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './front/register/register.component';
import { SearchResultComponent } from './front/search-result/search-result.component';
import { ServiceProviderComponent } from './front/service-provider/service-provider.component';
import { ServiceDetailComponent } from './front/services-list/service-detail/service-detail.component';
import { ServicesListComponent } from './front/services-list/services-list.component';
import { TermsAndConditionComponent } from './front/terms-and-condition/terms-and-condition.component';
import { EditProductComponent } from './admin/ad-service-product/edit-product/edit-product.component';
import { AddServiceProductComponent } from './admin/ad-service-product/edit-product/add-service-product/add-service-product.component';
import { DiscountComponent } from './admin/discount/discount.component';
import { AddDiscountComponent } from './admin/discount/add-discount/add-discount.component';
import { PageNotFoundComponent } from './front/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '404', component: PageNotFoundComponent },
  { path: 'sp-category', component: ServicesListComponent },
  {
    path: 'advertise-here/service-product',
    component: ServiceProductComponent,
  },
  { path: 'sp-category/:subcatid', component: ServicesListComponent },
  { path: 'service-providers/:id', component: ServiceDetailComponent },
  { path: 'service-providers-preview/:id', component: ServiceDetailComponent },
  { path: 'create-post', component: CreatePostComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'ad-fees', component: AdFeesComponent },
  { path: 'contact-us/:postid/:sellerid', component: ContactUsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'adverts/reply-to-ad', component: ContactSellerComponent },
  { path: 'adverts/search-ads', component: SearchResultComponent },
  { path: 'category/:catid', component: SearchResultComponent },
  { path: 'category/:catid/:subcatid', component: SearchResultComponent },
  { path: 'advertise-here/edit-info', component: InfoComponent },
  { path: 'advertise-here/manage-my-page', component: ManageMyPageComponent },
  { path: 'advertise-here/edit-profile', component: AccountInfoComponent },
  { path: 'adverts/edit-ad', component: ViewAdsComponent },
  { path: 'adverts/draft-ad', component: ViewAdsComponent },
  { path: 'adverts/expire-ad', component: ViewAdsComponent },
  { path: 'adverts/view-wishlist', component: MyWishlistComponent },
  { path: 'adverts/view-messages', component: MessagesComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginfrontComponent },
  { path: 'forgot-password', component: ForgotPasswordFrontComponent },
  { path: 'reset-password/:code', component: ResetPasswordFrontComponent },
  { path: 'terms-conditions', component: TermsAndConditionComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'register-service-provider', component: ServiceProviderComponent },
  { path: 'ad-detail', component: AdDetailComponent },
  // { path: 'ad-detail/:id', component: AdDetailComponent },
  { path: 'ad-detail/:adtitle', component: AdDetailComponent },
  { path: 'admin/login', component: LoginComponent },
  { path: 'admin/forgotpassword', component: ForgotPasswordComponent },
  { path: 'admin/reset-password/:code', component: ResetPasswordComponent },
  { path: 'admin/listing', component: ListingComponent },
  { path: 'admin/listing/add', component: AddComponent },
  {
    path: 'admin',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/category',
    component: CategoryComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/category/add',
    component: AddCategoryComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/category/edit/:id',
    component: AddCategoryComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/price-management/add',
    component: AddPriceManagementComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/price-management',
    component: PriceManagementComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/price-management/edit/:id',
    component: AddPriceManagementComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/contact-notification',
    component: ContactNotiComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/contact-monitoring',
    component: ContactMonitoringComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/user-management/add',
    component: AddUserComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/user-management/edit/:id',
    component: AddUserComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/user-management',
    component: UserManagementComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/ad-management/add',
    component: AddAdsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/ad-management/add/:userid',
    component: AddAdsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/ad-management/edit/:id',
    component: AddAdsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/ad-management/edit/:id/:userid',
    component: AddAdsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/ad-management',
    component: AdsManagementComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/ad-management/:userid',
    component: AdsManagementComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/cms-management/add',
    component: AddCmsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/cms-management/edit/:id',
    component: AddCmsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/cms-management',
    component: CmsManagementComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/banner-ads/add',
    component: AddCmsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/banner-ads/edit/:id',
    component: AddCmsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/banner-ads',
    component: CmsManagementComponent,
    canActivate: [LoggedInGuard],
  },

  {
    path: 'admin/faq/add',
    component: AddCmsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/faq/edit/:id',
    component: AddCmsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/faq',
    component: CmsManagementComponent,
    canActivate: [LoggedInGuard],
  },

  {
    path: 'admin/service-product',
    component: AdServiceProductComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/service-product/add',
    component: AddProductComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/service-product/edit/:id',
    component: AddProductComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/service-product/view-product/:id',
    component: EditProductComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/service-product/view-product/:id/add-product',
    component: AddServiceProductComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/service-product/view-product/:id/edit-product/:productId',
    component: AddServiceProductComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/my-profile',
    component: MyProfileComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/discount',
    component: DiscountComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/discount/add',
    component: AddDiscountComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'admin/discount/edit/:id',
    component: AddDiscountComponent,
    canActivate: [LoggedInGuard],
  },
  { path: '', component: HomeComponent },
  { path: 'myaccount', component: MyaccountComponent },
  { path: 'adverts/place-ad', component: PostAdComponentBeta },
  { path: 'adverts/place-ad/:id', component: PostAdComponentBeta },

  // Stripe beta
  { path: 'adverts/place-ad-beta', component: PostAdComponentBeta },
  { path: 'adverts/place-ad-beta/:id', component: PostAdComponentBeta },
  // { path: 'adverts/place-ad/:adtitle', component: PostAdComponent },

  { path: '**', component: HomeComponent },
];
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes), Angulartics2Module.forRoot()],
})
export class AppRoutingModule {}
